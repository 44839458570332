import { createApp } from 'vue';
import App from '@/components/App.vue';
import AppContext from '@/composables/useApp';

import '@/assets/icons';
import router from '@/services/router';
import config from '@/configs';

import { IonicConfig } from '@ionic/core';
import { IonicVue } from '@ionic/vue';
import { pinia } from '@/services/stores';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* App styles */
import './assets/scss/main.scss';

import backButtonIcon from '@/assets/icons/chevron-left.svg';
import AppGlobalComponents from '@/components/global';

import { AppRepositories, AppRepositoriesConfig } from '@/services/repositories';

if (Telegram) {
  Telegram.WebApp.expand();
}

const ionicVueConfig: IonicConfig = {
  mode: 'ios',
  swipeBackEnabled: true,
  backButtonText: '',
  backButtonIcon,
};

const repositoriesConfig: AppRepositoriesConfig = {
  baseUrl: config.baseUrl,
};


const app = createApp(App)
  .use(AppContext)
  .use(IonicVue, ionicVueConfig)
  .use(router)
  .use(pinia)
  .use(AppGlobalComponents)
  .use(AppRepositories, repositoriesConfig)
;

router.isReady().then(() => {
  app.mount('#app');
});
