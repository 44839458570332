import { AsyncResponse, BaseRepository } from './BaseRepository';
import { UserCarsResponse, User } from '@/services/repositories/models/User';
import { CreateVisitDto, Visit } from '@/services/repositories/models/visit';

export class UserRepository extends BaseRepository {
  /**
   * Получение текущего пользователя
   *
   * @returns
   */
  getCurrentUser(): AsyncResponse<User> {
    return this.client.get('/v1/telegram/user');
  }

  /**
   * Получение списка автомобилей пользователя
   *
   * @returns
   */
  getUserCars(): AsyncResponse<UserCarsResponse> {
    return this.client.get('/v1/telegram/user/cars');
  }

  // createUserCar(): AsyncResponse<void> {
  //   return this.client.post('');
  // }

  /**
   * Получение списка визитов пользователя
   *
   * @returns
   */
  getUserVisits(): AsyncResponse<{ data: Visit[] }> {
    return this.client.get('/v1/telegram/user/cars/visits');
  }

  /**
   * Получение визита по ID
   *
   * @returns
   */
  getVisitById(visitId: string): AsyncResponse<{ data: Visit[] }> {
    return this.client.get(`/v1/telegram/user/cars/visits/${visitId}`);
  }

  /**
   * Создать визит пользователя
   *
   * @returns
   */
  createVisit(payload: CreateVisitDto): AsyncResponse<{ data: Visit }> {
    return this.client.post('/v1/telegram/user/cars/visits', payload);
  }
}
