import { devlog } from '@/helpers/dev';
import { RemovableRef } from '@vueuse/core';
import { Ref, ref, shallowRef, computed, isRef } from 'vue';

export interface UseRequestWrapOptions<T, D = T> {
  request(): Promise<T>;
  initialData: D|Ref<D>|RemovableRef<D>;
}

export function useSingleRequestDataWrap<T, D = T>(options: UseRequestWrapOptions<T, D>) {
  const data = isRef(options.initialData)
    ? options.initialData
    : ref(options.initialData) as Ref<D|T>;
  
  const loading = ref(false);
  const isActial = ref(false);
  const isFetched = ref(false);
  const error: Ref<any> = shallowRef(null);

  /** Общание последнего запроса (блокирует выполенния еще одного паралельного запроса и позволяет дождаться уже начавшийся) */
  let fetchPromise: Promise<void>|null = null;

  /** Отображает статус обновления данных (при первом запросе будет false, в последующем при загрузке (loading) данных будет true)*/
  const updating = computed(() => isFetched.value && loading.value);

  /**
   * Внутренний запрос, запрашивающий обновление данных.
   * Если будет вызываться во время выполнения другого запроса, то возникнет ошибка.
   */
  async function internalFetch(): Promise<void> {
    if (loading.value) {
      throw new Error('Запрос уже выполняется, повторный запуск без завершения предыдущего - невозможен');
    }

    loading.value = true;

    try {
      data.value = await options.request() as T;

      error.value = null;

      isFetched.value = true;
      isActial.value = true;
      loading.value = false;
    } catch (e: any) {
      error.value = e;
      loading.value = false;
      throw e;
    }
  }

  /**
   * Запрос, для получения последних данных.
   * В случае, если запрос уже выполняется, дождется выполнения предыущего, уже запущенного запроса.
   * 
   * @returns 
   */
  async function fetch(): Promise<void> {
    if (fetchPromise) {
      devlog('Повторный запрос остановлен, дожидаемся обработки предыдущего', fetchPromise);
      await fetchPromise;
      return;
    }

    fetchPromise = internalFetch();

    try {
      await fetchPromise;
      fetchPromise = null;
    } catch (e: any) {
      fetchPromise = null
      throw e;
    }
  }

  /**
   * Проверит актуальность данных (по флагам),
   * и если занные не актуальны, запустит процесс их обновления.
   */
  async function actualize(): Promise<void> {
    if (!isFetched.value || !isActial.value) {
      await fetch();
    }
  }

  return {
    data,
    loading,
    isActial,
    isFetched,
    error,
    fetch,
    actualize,
    updating,

    internalFetch,
  };
}

/**
 * В случае если метод уже выполняется (асинхронное выполнение функции), то он дожидается выполнения предыдущего
 * 
 * FIXME: Возможно написан с ошибкой
 * 
 * @param requestHandler 
 * @returns 
 */
export function defineSingleRequestMethod<T extends (...args: any[]) => Promise<any>>(requestHandler: T): T {
  let promiseActiveInstance: Promise<any>|null = null;

  return (async (...args: any[]) => {
    if (!promiseActiveInstance) {
      promiseActiveInstance = requestHandler.call(null, ...args);
    }

    try {
      const res = await promiseActiveInstance;
      promiseActiveInstance = null;
      return res;
    } catch (e: any) {
      promiseActiveInstance = null;
      throw e;
    }
  }) as any as T;
}
