import {computed, ComputedRef, reactive, UnwrapRef} from "vue";
import {BaseStore, BaseStoreContext} from "@/services/stores/BaseStore";
import {ServiceCategories, ServiceTypes} from "@/services/repositories/models/service";
import {isClientError} from "@/services/repositories";

// TODO:
// - Перейти на Pinia !?
// - Убрать запросы в try блоки
// - Избавиться от localStorage.removeItem('token');
// - Сделать корректную проверку на то что клиент не авторизован (401)
// - Прокомментировать все методы

export interface PointStoreState {
  serviceTypes: ServiceTypes|null;
  currentCategory: ServiceCategories|null;
  selectedServices: ServiceTypes|null;
}

export class ServiceStore extends  BaseStore {
  readonly state: UnwrapRef<PointStoreState>;
  readonly serviceTypes: ComputedRef<ServiceTypes|null>;
  readonly currentCategory: ComputedRef<ServiceCategories|null>;
  readonly selectedServices: ComputedRef<ServiceTypes|null>;

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.state = reactive({
      serviceTypes: null,
      currentCategory: null,
      selectedServices: null
    });

    this.serviceTypes = computed(() => this.state.serviceTypes);
    this.currentCategory = computed(() => this.state.currentCategory);
    this.selectedServices = computed(() => this.state.selectedServices);
  }

  async getServiceTypes() {
    const { data } = await this.repositories.service.getServicesTypes(true);

    try {
      this.state.serviceTypes = data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }

  async getServiceCategory(categoryId: number) {
    const { data } = await this.repositories.service.getCategoryById(categoryId);

    try {
      this.state.currentCategory = data.data;
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }

      throw e;
    }
  }
}
