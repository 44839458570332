import {AsyncResponse, BaseRepository } from './BaseRepository';
import {
  CarBrandsCatalogResponse,
  CarBrandsCatalogQuery,
  CarModelsCatalogResponse,
  CarModelsCatalogQuery
} from '@/services/repositories/models/car';

export class CarRepository extends BaseRepository {
  /**
   * Получить список производителей авто
   *
   * @returns
   */
  getBrandsCatalog(params: CarBrandsCatalogQuery): AsyncResponse<CarBrandsCatalogResponse> {
    return this.client.get('/v1/cars/catalog/brands', { params });
  }

  /**
   * Список моделей автомобилей по марке
   * 
   * @param brand 
   * @param params 
   * @returns 
   */
  getModelsCatalog(brand: string, params: CarModelsCatalogQuery): AsyncResponse<CarModelsCatalogResponse> {
    return this.client.get(`/v1/cars/catalog/brands/${brand}/models`, { params });
  }
}
