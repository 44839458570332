import { BaseRepository, AsyncResponse } from './BaseRepository';
import { AuthSendSmsCodeBody, AuthPhoneBody, CheckAccountResponse } from './models/auth';
import camelCaseKeys from 'camelcase-keys';

export class AuthRepository extends BaseRepository {
  /**
   * Проверить привязан ли текущий пользователь телеграмм к аккаунту Core12
   * 
   * @returns 
   */
  checkAccount(): AsyncResponse<CheckAccountResponse> {
    return this.client.get('/v1/telegram/auth', {
      // FIXME: Временно поправил нотацию тут, нужно поправить ее на сервере!
      transformResponse: [data => camelCaseKeys(data)],
    });
  }

  /**
   * Отправить СМС-код подтверждение на указанный номер.
   * 
   * В случае успеха вернет пустое тело запроса с кодом - 201
   * 
   * Ошибки:
   * > 400 - Неверные параметры
   * > 401 - Неверные данные авторизации (initData от TG)
   * > 409 - Этот номер телефона уже привязан к текущему пользователю
   * > 425 - СМС не может быть отправлено снова так быстро
   * 
   * @param body 
   * @returns 
   */
  sendSmsCode(body: AuthSendSmsCodeBody): AsyncResponse<void> {
    return this.client.post('/v1/telegram/auth/phone', body);
  }

  /**
   * Аутентификация по номеру телефона.
   * - Привяжет указанный номер телефона к текущему аккаунту TG
   * - Создаст нового пользователя в случае его отсутствия (в БД)
   * - Привяжет к созданному пользователю все его ранее созданые визиты (если до этого пользователь не входил и у него есть визиты)
   * 
   * > 200 - пользователь существует и успешно подтвержден
   * > 201 - пользователь успешно создан и привязан (ранее не входил в систему)
   * 
   * Ошибки:
   * > 400 - Неверные параметры
   * > 401 - Неверные данные авторизации
   * > 404 - СМС с кодом подтверждения не было отправлено на указанный номер
   * 
   * @param body 
   * @returns 
   */
  authPhone(body: AuthPhoneBody): AsyncResponse<void> {
    // TODO: 200 - возвращает ответ { consent_agreed: true } - узнать зачем, и почему в такой нотации
    return this.client.put('/v1/telegram/auth/phone', body)
  }

  /**
   * Отвязать текущего пользователя Telegram, от аккаунта Core12
   * 
   * @returns 
   */
  detachCurrentUser(): AsyncResponse<void> {
    return this.client.delete('/v1/telegram/auth');
  }
}
