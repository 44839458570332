import * as IonicComponents from './ionic';
import { App, Component } from 'vue';

import AppHeader from './layout/AppHeader.vue';
import AppFooter from './layout/AppFooter.vue';
import AppHeaderClean from './layout/AppHeaderClean.vue';
import CPrice from './share/CPrice.vue';
import CTitleBlock from './share/CTitleBlock.vue';
import CCardProgress from './share/CCardProgress.vue';

export const AppGlobalComponents: {[componentName: string]: Component } = {
  AppHeader,
  AppFooter,
  AppHeaderClean,
  CPrice,
  CTitleBlock,
  CCardProgress,
};

export default {
  install(app: App) {
    // Ionic
    for (const [IonComponentName, IonComponent] of Object.entries(IonicComponents)) {
      app.component(IonComponentName, IonComponent);
    }

    // App
    for (const [AppComponentName, AppComponent] of Object.entries(AppGlobalComponents)) {
      app.component(AppComponentName, AppComponent);
    }
  }
}
