import { App, inject } from 'vue';
import config from '@/configs';
import { isDevMode } from '@/helpers/dev';
import { devConfigStorage } from '@/services/stores/dev';
import { createStore, StoreInstance } from '@/services/stores';
import { createClient, createRepositories } from '@/services/repositories';

export const LS_KEY_BASE_DOMAIN = 'app/baseDomain';

/**
 * TODO:
 * - Убрать повторное создание api клиента и репозиторией (прямо беда)
 * 
 * @returns 
 */
export function createAppContext() {
  const apiDomain = getApiDomain();
  const httpClient = createClient(`${apiDomain}${config.apiPath}`);
  const repositories = createRepositories(httpClient);

  const store = createStore(repositories, httpClient);

  function getApiDomain() {
    if (isDevMode() && !!devConfigStorage.value.baseUrl.trim()) {
      return devConfigStorage.value.baseUrl;
    }

    return config.baseUrl;
  }

  return {
    http: httpClient,
    repositories,

    get store() { return store; },

    destroy() {
      throw new Error('AppContext.destroy() не реализована!');
    },
  };
}

export type AppContext = ReturnType<typeof createAppContext>;

export interface AppContextConfig {
  context?: AppContext;
  global?: boolean;
  injectKey?: string;
}

export default {
  install(app: App, config: AppContextConfig = {}) {
    const appContext = config.context || createAppContext();

    if (config.global !== false) {
      app.config.globalProperties.$app = appContext;
      Object.defineProperty(app.config.globalProperties, '$store', {
        get() { return appContext.store; }
      });
    }

    app.provide(config.injectKey || 'appContext', appContext);
  }
};

export function useStore(appContextKey?: string): StoreInstance {
  const appContext = inject(appContextKey || 'appContext') as AppContext;
  return appContext.store;
}
