import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export interface DevConfig {
  devMode: boolean;
  baseUrl: string;
  tgInitData: string;
}

/**
 * Хранилице с настройками разработчика
 * 
 * NOTE: Сделал его спуциально отдельно [не внутри defineStore()],
 * чтобы можно было исаользовать глобально и вне контекста vue.
 */
export const devConfigStorage = useLocalStorage<DevConfig>('devConfig', {
  devMode: false,
  baseUrl: '',
  tgInitData: '',
});

export const useDevStore = defineStore('devStore', () => {
  const configState = devConfigStorage;

  return {
    configState,
  };
});