import { devConfigStorage } from '@/services/stores/dev';

export function isDevMode() {
  return devConfigStorage.value.devMode;
}

/**
 * Задержка в МС
 * 
 * @param ms значение задержки
 * @param success вероятность успешного ответа (1 - 100%)
 */
export function devSleepResult(ms = 1500, success = 1): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Math.random() > (1 - success)) {
        resolve();
      } else {
        reject();
      }
    }, ms);
  });
}

export function devlog(...params: any): void {
  if (isDevMode()) {
    console.log(...params);
  }
}