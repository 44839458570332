import { AsyncResponse, BaseRepository } from './BaseRepository';
import type {
  CheckoutCalculateRequestBody,
  CheckoutDatetimesRequestBody,
  CheckoutCalculateResponse,
  CheckoutDatetimeseResponse,
} from '@/services/repositories/models/checkout';

export class CheckoutRepository extends BaseRepository {
  calculate(body: CheckoutCalculateRequestBody): AsyncResponse<CheckoutCalculateResponse> {
    return this.client.post('v1/telegram/user/cars/checkout/calculate', body);
  }

  getDatetimes(body: CheckoutDatetimesRequestBody): AsyncResponse<CheckoutDatetimeseResponse> {
    return this.client.post('v1/telegram/user/cars/checkout/datetimes', body);
  }
}
