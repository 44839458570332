import { ref, computed, shallowRef, watch } from 'vue';
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { UserData, User } from '@/services/repositories/models/User';
import { isClientError, useRepositories } from '../repositories';
import { useAuthStore } from './auth';

export interface CurrentUserInformationState {
  current: UserData|null;
}

export const useUserStore = defineStore('userStore', () => {
  const repositories = useRepositories();
  const authStore = useAuthStore();

  //#region Current User Information
  const current = useLocalStorage<User|null>('currentUserInformation', null);
  const currentIsActial = ref(false);
  const currentLoadingPromise = shallowRef<Promise<User>|null>(null);
  const currentLoading = computed(() => !!currentLoadingPromise.value);

  watch(() => authStore.isCore12Auth, isAuth => {
    if (isAuth === false) {
      current.value = null;
      currentIsActial.value = false;
    }
  });

  const profile = computed(() => {
    return {
      data: current.value?.data || null,
      notifications: current.value?.notifications || null,
      isActual: currentIsActial.value,
      loading: currentLoading.value,
    };
  });

  /**
   * Загрузить обновленную информацию о текущем пользователе с сервера
   * 
   * @returns 
   */
  function fetchCurrentInformation(): Promise<User> {
    if (currentLoadingPromise.value) {
      return currentLoadingPromise.value;
    }

    currentLoadingPromise.value = new Promise<User>((resolve, reject) => {
      repositories.user.getCurrentUser()
        .then(res => {
          updateCurrentHandler(res.data);
          resolve(res.data);
        })
        .catch(err => {
          catchCurrentHandler(err);
          reject(err);
        })
        .finally(() => {
          currentLoadingPromise.value = null;
        })
      ;
    });

    return currentLoadingPromise.value;
  }

  /**
   * Актуализация текущей информации о пользователе
   * Если информация не актуальна, загрузит с сервера, если актуальна, то ничего не сделает.
   */
  async function actualizeCurrentInformation(): Promise<void> {
    if (currentIsActial.value === false) {
      await fetchCurrentInformation();
    }
  }

  /**
   * Обновить данные текущего пользователя
   * 
   * @param user 
   * @returns 
   */
  function updateCurrentHandler(user: User|null) {
    if (!user) {
      clearCurrent();
      return;
    }

    current.value = user;
    currentIsActial.value = true;
  }

  /**
   * Обработка ошибок, во время получения данных пользователя
   * 
   * @param error 
   * @returns
   */
  function catchCurrentHandler(error: any) {
    if (isClientError(error)) {
      switch (error.status) {
        case 401: // Пользователь не авторизован
        case 404: // Пользователь не найден
          clearCurrent();
          return;
      }
    }

    // Другие необработванные ошибки, пометят профиль, как неактуальный
    currentIsActial.value = false;
  }

  /**
   * Очистит информацию о текущем пользователе
   */
  function clearCurrent() {
    current.value = null;
    currentIsActial.value = false;
  }
  //#endregion End Current User Information

  return {
    profile,
    currentIsActial,
    fetchCurrentInformation,
    actualizeCurrentInformation,
    clearCurrent,
  };
});
