const CID_IN_PATHNAME = /^\/cid-(\d{1,10})\/?.*$/;

/**
 * Попробует получить Core Id (CID) из адресной строки
 * 
 * /cid-828/outher/path - вернет 828
 * 
 * @returns 
 */
export function getCoreIdFromPathname(): string|null {
  if (CID_IN_PATHNAME.test(location.pathname)) {
    return location.pathname.replace(CID_IN_PATHNAME, '$1');
  }

  return null;
}