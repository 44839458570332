import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { getCoreIdFromPathname } from '@/utils/coreid';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: { name: 'car-model-choice' }
    // redirect: '/services/choice'
  },
  {
    path: '/car/model-choice',
    name: 'car-model-choice',
    component: () => import('@/views/car/ChooseModelCarPage.vue')
  },
  {
    path: '/services/choice',
    name: 'services-choice',
    component: () => import('@/views/ServicesChoice.vue')
  },
  {
    path: '/checkout/datetime',
    name: 'checkout-datetime',
    component: () => import('@/views/CheckoutDatetime.vue')
  },
  {
    path: '/account',
    name: 'account-main',
    component: () => import('@/views/account/AccountMainPage.vue')
  },
  {
    path: '/account/login',
    name: 'account-login',
    component: () => import('@/views/account/AccountAuthPage.vue')
  },
  {
    path: '/account/visits/:visitId',
    name: 'visit-single',
    component: () => import('@/views/visit/VisitSingle.vue'),
    props: true,
  },
  {
    path: '/account/visits/:visitId/chat',
    name: 'visit-chat',
    component: () => import('@/views/chat/ChatServicePage.vue')
  },

  //#region For Dev
  {
    path: '/dev',
    name: 'dev',
    redirect: '/dev/navigation'
  },
  {
    path: '/dev/navigation',
    name: 'dev-navs',
    component: () => import('@/views/dev/DevNavigation.vue')
  },
  {
    path: '/dev/config',
    name: 'dev-config',
    component: () => import('@/views/dev/DevConfigPage.vue')
  },
  {
    path: '/dev/components',
    name: 'dev-components',
    component: () => import('@/views/dev/DevComponents.vue')
  },
  {
    path: '/dev/binding-phone',
    name: 'dev-bind-phone',
    component: () => import('@/views/dev/DevBindingPhone.vue')
  }
  //#endregion
];

export const cid = getCoreIdFromPathname();

export const router = createRouter({
  history: createWebHistory(cid ? `${process.env.BASE_URL}cid-${cid}` : process.env.BASE_URL),
  routes
});

export default router;
