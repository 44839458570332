import { addIcons } from 'ionicons';
import { mapKeys, kebabCase } from 'lodash';

import {
} from 'ionicons/icons';

import UserCircle from './user-circle.svg';
import Plus from './plus.svg';
import Minus from './minus.svg';
import Chat from './chat.svg';
import ChevronLeft from './chevron-left.svg';
import ChevronRight from './chevron-right.svg';
import ClockCircle from './clock-circle.svg';
import Pay from './pay.svg';
import Pos from './pos.svg';
import PercentCircle from './percent-circle.svg';
import Setting from './setting.svg';
import Core from './core12.svg';

addIcons({
  //#region Ionic Icons
  ...mapKeys({
    // ...
  }, (value, key) => kebabCase(key)),
  //#endregion Ionic Icons

  //#region Core12 Icons
  ...mapKeys({
    UserCircle,
    Plus,
    Minus,
    Chat,
    ChevronLeft,
    ChevronRight,
    ClockCircle,
    Pay,
    Pos,
    PercentCircle,
    Setting,
    Core,
  }, (value, key) => 'c12c-' + kebabCase(key)),
  //#endregion Core12 Icons
});
