import { BaseRepository, AsyncResponse } from './BaseRepository';

export class ServiceRepository extends BaseRepository {
  getServicesTypes(withCategories: boolean): AsyncResponse<any> {
    return this.client.get(`/v1/telegram/services/types?withCategories=${withCategories}`);
  }

  getCategoryById (categoryId: number) {
    return this.client.get(`/v1/telegram/services?pointTypeId=${categoryId}`);
  }
}
