import { App as VueApp, inject } from 'vue';
import createClient, { ClientInstance } from './clients/AxiosClient';

import { UserRepository } from './UserRepository';
import { ServiceRepository } from './ServiceRepository';
import { AuthRepository } from './AuthRepository';
import { CarRepository } from './CarRepository';
import { ChatRepository } from './ChatRepository';
import { CheckoutRepository } from './CheckoutRepository';
import { isDevMode } from '@/helpers/dev';
import { devConfigStorage } from '@/services/stores/dev';
import globalConfig from '@/configs';

const HTTP_CLIENT_PROVIDE_KEY = 'httpClient';
const REPOSITORIES_PROVIDE_KEY = 'repositories';

export type Repositories = ReturnType<typeof createRepositories>;
export * from './clients/AxiosClient';
export type { ClientInstance };

export function createRepositories(client: ClientInstance) {
  return {
    user: new UserRepository(client),
    service: new ServiceRepository(client),
    chats: new ChatRepository(client),
    auth: new AuthRepository(client),
    car: new CarRepository(client),
    checkout: new CheckoutRepository(client),
  };
}

export interface AppRepositoriesConfig {
  baseUrl: string;
}

export const AppRepositories = {
  install(app: VueApp, config: AppRepositoriesConfig) {
    if (!config || !config.baseUrl) {
      throw new Error('Не передан baseUrl для инициализации http-клиента');
    }

    /**
     * Получить итоговый базовый URL для доступа к API
     * 
     * TODO: Вынести его в более аодходящее место (здесь пока временно)
     */
    function getFinalBaseUrl() {
      if (isDevMode() && !!devConfigStorage.value.baseUrl.trim()) {
        return devConfigStorage.value.baseUrl + globalConfig.apiPath;
      }
  
      return config.baseUrl + globalConfig.apiPath;
    }

    const client = createClient(getFinalBaseUrl());
    const repositories = createRepositories(client);

    app.config.globalProperties.$repositories = repositories;
    app.config.globalProperties.$http = client;

    app.provide(REPOSITORIES_PROVIDE_KEY, repositories);
    app.provide(HTTP_CLIENT_PROVIDE_KEY, repositories);
  }
};

/**
 * Получение списка репозиториев, для работы с Api
 *
 * @returns
 */
export function useRepositories(): Repositories {
  const repositories = inject(REPOSITORIES_PROVIDE_KEY) as Repositories|undefined;

  if (!repositories) {
    throw new Error('Для получения репозиториев необходимо их инициализировать');
  }

  return repositories;
}

/**
 * Получение http-клиента, для работы с Api
 *
 * @returns
 */
export function useHttpClient(): ClientInstance {
  const client = inject(HTTP_CLIENT_PROVIDE_KEY) as ClientInstance|undefined;

  if (!client) {
    throw new Error('Для получения http-клиента его необходимо инициализировать');
  }

  return client;
}
