import { BaseRepository, AsyncResponse } from './BaseRepository';
import {ChatMessages, ChatUpdates, SendingMessage} from "@/services/repositories/models/Chat";

export class ChatRepository extends BaseRepository {
  getUserChats(): AsyncResponse<any> {
    return this.client.get('/v1/telegram/user/chats');
  }

  getChatUpdates(chatId: string): AsyncResponse<ChatUpdates> {
    return this.client.get(`/v1/telegram/user/chats/${chatId}/updates`);
  }

  getChatMessages(chatId: string): AsyncResponse<ChatMessages> {
    return this.client.get(`/v1/telegram/user/chats/${chatId}/messages`);
  }

  sendMessage(chatId: string, payload: SendingMessage): AsyncResponse<ChatMessages> {
    return this.client.post(`/v1/telegram/user/chats/${chatId}/messages`, payload);
  }
}
